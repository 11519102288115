import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { makeStyles, Paper, Box, Button } from '@material-ui/core';

import routineSVG from './img/routine.svg';
import diabeticSVG from './img/diabetic.svg';
import orthoticsSVG from './img/orthotics.svg';
import stockingsSVG from './img/stockings.svg';
import toenailSVG from './img/toenail.svg';

import CloseIcon from '@material-ui/icons/Close';

var useStyles = makeStyles(theme => ({
    itemTitle: {
        [theme.breakpoints.down("sm")]: {
            fontSize: 18
        },
        [theme.breakpoints.up("md")]: {
            fontSize: 24
        },
        marginTop: 0,
        marginBottom: 0,
        fontWeight: 400,
        fontFamily: "Playfair Display",
        letterSpacing: 1
    },
    itemSubTitle: {
        [theme.breakpoints.down("sm")]: {
            fontSize: 12
        },
        [theme.breakpoints.up("md")]: {
            fontSize: 14
        },
        marginTop: 0,
        marginBottom: 0,
        fontWeight: 300,
    },
    itemDescription: {
        [theme.breakpoints.down("sm")]: {
            fontSize: 14
        },
        [theme.breakpoints.up("md")]: {
            fontSize: 20
        },
        fontWeight: 500,
        marginTop: 1,
        textAlign: "left",
        paddingLeft: "1em",
        paddingRight: "1em",
    },
    gradientStrip: {
        background: "linear-gradient(to left, #edc4b3, #c38e70, #774936)"
    },
    tip: {
        listStyleType: "none",
        textAlign: "left",
        marginBottom: 8,
        [theme.breakpoints.down("sm")]: {
            fontSize: 14
        },
        [theme.breakpoints.up("md")]: {
            fontSize: 20
        },
    },
}));

function ServicesSection() {

    var items = [
        {
            title: "Routine Foot Care",
            subTitle: "The first step to healthy feet",
            src: `${routineSVG}`,
            description: "At Heel To Toes, we believe that the best treatment plan for your feet’s conditions is preventing them from occurring in the first place. That is why we offer information and guidance related to routine foot care in. With just a few simple steps, you can avoid a great deal of pain and discomfort, and ensure that your feet remain healthy and strong for years to come.",
            tipsHeader: "Just a few tips for healthier feet",
            tips: [
                "Wash your feet everyday with soap and water to remove dirt and bacteria",
                "Moisturize the soles of your feet to prevent cracks and calluses from forming",
                "Wear shoes that fit you properly and don’t rub against your skin",
                "Wear dry socks and shoes, as moisture helps bacteria grow and develop",
                "Use foot powder to prevent excess moisture",
                "Avoid going barefoot in public spaces, including pools, locker rooms and exercise facilities",
                "Trim your nails in a straight horizontal line to avoid ingrown toenails from forming",
                "Bathe your feet in salt water to easily remove dry skin",
                "Examine your feet on a regular basis to identify any problems"
            ]
        },
        {
            title: "Diabetic Foot Care",
            subTitle: "Diabetes and your feet",
            src: `${diabeticSVG}`,
            description: "Diabetes is a chronic condition where the body is unable to naturally regulate its blood sugar level. While manageable with medication and monitoring, there are side effects that can seriously affect your feet and lower limbs. At Heel To Toes, we are committed to providing you with important information and guidance relating to diabetic foot care.",
            tipsHeader: "Some things to consider",
            tips: [
                "Take insulin and other medications as prescribed by your doctor",
                "Wear shoes that fit properly and don’t rub or push against your skin",
                "Check your feet on a daily basis for blisters, cuts, sores and other injuries",
                "Immediately go the doctor at the first sign of infection"
            ]
        },
        {
            title: "Custom Foot Orthotics",
            subTitle: "Alleviate your foot & ankle symptoms",
            src: `${orthoticsSVG}`,
            description: "Custom orthotics are medical devices made to alleviate your foot & ankle symptoms. Custom orthotics are designed to realign the foot, improve function and correct abnormal biomechanics. Custom orthotics will assist in treatment and prevention of many foot, ankle, knee and back disorders. Custom orthotics will allow you to stay healthy and active without worrying about pain.",
            tipsHeader: "Some of the brands our patients love",
            tips: [
                "Drew",
                "Anodyne",
                "Apex",
                "Orthofeet",
            ]
        },
        {
            title: "Compression Stockings",
            subTitle: "Defeat that aching feeling",
            src: `${stockingsSVG}`,
            description: "Compression stockings provide support to your veins and help the calf muscle pump blood back to the heart. Compression stockings help reduce swelling and decrease the tired, aching feeling that you get in your legs. For anyone dealing with edema, varicose veins, spider veins, chronic venous insufficiency or deep vein thrombosis (DVT), compression stockings are what you need.",
            tipsHeader: "Who can benefit from compression stocking?",
            tips: [
                "People whose jobs require them to stand for long periods of time",
                "Athletes - competitive and recreational",
                "Tourism & vacation enthusiasts"
            ]
        },
        {
            title: "Toe Nail Surgeries",
            subTitle: "Handled with care by professionals",
            src: `${toenailSVG}`,
            description: "Toenail surgery is a form of elective surgery that can be undertaken by one of our skilled Chiropodists. In a partial nail avulsion a piece of toenail is removed from the side that is problematic and commonly a chemical is applied to prevent that piece of nail growing back again. Sometimes the degree of toenail problems means that the Chiropodist will discuss removal of the whole nail.",
            tipsHeader: "Feel good in your feet",
            tips: []
        }
    ];
    return (
        <Carousel autoPlay={false} navButtonsAlwaysVisible>
            {items.map( (item, index) => <Item entry={item} identifier={index} key={index} /> )}
        </Carousel>
        
    );
}

function Item(props)
{
    const classes = useStyles(); 
    return (
        <Box width={{ xs: 1, md: 1/2}} mx="auto" pb={2} style={{ height: { xs: "300px", md: "350px" } }}>

            <InfoModal id={props.identifier} info={props.entry}></InfoModal>

            <Paper elevation={3} style={{ height: { xs: "300px", md: "350px" } }}>
                <Box width={1} display="flex" flexDirection="row" px={1} justifyContent="center">
                    <Box width={{xs: 1/4, md: 1/5}} pr={1} mt={2}>
                        <img src={props.entry.src}></img>
                    </Box>
                    <Box width="auto" textAlign="left" my="auto" pl={1}>
                        <h5 className={classes.itemSubTitle}>{props.entry.subTitle}</h5>
                        <h2 className={classes.itemTitle}>{props.entry.title}</h2>
                    </Box>
                </Box>
                
                <Box className={classes.itemDescription} mb={2} display={{ xs: "none", md: "block" }}>{props.entry.description}</Box>

                <Button my={2} style={{ color: "#edc4b3" }} onClick={() => {
                    document.getElementById(props.identifier).style.display = "block";
                }}>
                    Learn More
                </Button>
            </Paper>
        </Box>
        
    );
}

function InfoModal(props) {
    let classes = useStyles();
    let entry = props.info;
    return (
        <Box id={props.id} position="fixed" top={0} left={0} style={{ display: "none" }} width="100vw" height="100vh" zIndex={5}>
            <Box width={1} height={1} position="absolute" zIndex={7} display="flex">
                <Box bgcolor="#eee" width={3/4} height={3/4} m="auto" overflow="auto">
                    <Box>
                        <Box ml="auto" width="64px" height="42px"><Button onClick={() => {
                            document.getElementById(props.id).style.display = "none";
                        }}><CloseIcon></CloseIcon></Button></Box>
                        <h5 className={classes.itemSubTitle}>{entry.subTitle}</h5>
                        <h2 className={classes.itemTitle}>{entry.title}</h2>
                        <Box className={classes.gradientStrip} my={1} mx={1} height="3px" boxShadow={2}></Box>
                        <p className={classes.itemDescription}>{entry.description}</p>
                        <Box className={classes.gradientStrip} height="24px" color="#fff" fontSize="12px" display="flex" alignItems="center" px={2}>{entry.tipsHeader}</Box>
                        <ul style={{ paddingLeft: "8px", paddingRight: "4px" }}>
                            {
                                entry.tips.map((tip, index) => 
                                    <li className={classes.tip} key={index}>
                                        <span style={{color: "#edc4b3", marginRight: 10}}>&#10010;</span>{tip}
                                    </li>
                                )
                            }
                        </ul>
                    </Box>
                </Box>
                
            </Box>
            <Box mt={1} position="absolute" bottom="2%" mx={{xs: "37%", md: "47%"}} mb={2} zIndex={7} display="flex" justifyContent="center">
                <Button variant="outlined" color="primary" onClick={() => { document.getElementById(props.id).style.display = "none"; }}>Close</Button>
            </Box>
            <Box bgcolor="#000" width={1} height={1} style={{ opacity: "75%" }} position="relative" zIndex={6}></Box>
        </Box>
    );
}

export default ServicesSection;