import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import './App.css';

// A custom theme for this app
const theme = responsiveFontSizes(
    createMuiTheme({
        typography: {
            fontFamily: [
                '"Noto Sans JP", sans-serif',
                '"Playfair Display", serif',
            ]
        },
        palette: {
            primary: {
                main: '#edc4b3',
                contrastText: '#000',
            },
            secondary: {
                main: '#fff',
                contrastText: '#5e6973'
            },
            error: {
                main: red.A400,
            },
            background: {
                default: '#e1e2e1',
            },
        },
}));

export default theme;