import React from 'react';
import { makeStyles, Button, Box } from '@material-ui/core';
import LogoIcon from './LogoIcon';

const useStyles = makeStyles(theme => ({
    link: {
        fontFamily: "Playfair Display",
        [theme.breakpoints.down("sm")]: {
            display: "none"
        },
        letterSpacing: "2px",
        color: "#fff"
    },
    navBar: {
        position: "absolute",
        width: "100vw",
        zIndex: 4,
        
    },
    icon: {
        fill: theme.palette.primary.contrastText,
    }
}));

export default function MenuBar () {
    const classes = useStyles();
    return (
        <nav id="menu-bar" className={classes.navBar}>
            <Box width={1} display="flex" flexDirection="row" justifyContent="center" alignItems="center" bgcolor="primary" mt={1}>
                <LogoIcon mx="auto" />
            </Box>
            
            <Box width={1} display="flex" flexDirection="row" justifyContent="center" alignItems="center" bgcolor="primary">
                <Box mx="auto" textAlign="center" width={1/6}>
                    <Button href="/#home" className={classes.link}>
                        HOME
                    </Button>
                </Box>

                <Box mx="auto" textAlign="center" width={1/6}>
                    <Button href="/#team" className={classes.link}>
                        OUR TEAM
                    </Button>
                </Box>

                <Box mx="auto" textAlign="center" width={1/6}>
                    <Button href="/#contact" className={classes.link}>
                        CONTACT US
                    </Button>
                </Box>

                <Box mx="auto" textAlign="center" width={1/6}>
                    <Button href="/#services" className={classes.link}>
                        SERVICES
                    </Button>
                </Box>

                <Box mx="auto" textAlign="center" width={1/6}>
                    <Button href="/#treatments" className={classes.link}>
                        TREATMENTS
                    </Button>
                </Box>
            </Box>            
        </nav>
    );
    
}