import React from 'react';
import { Box, makeStyles, Button, Paper, Grid } from '@material-ui/core';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import PhoneIcon from '@material-ui/icons/Phone';
import PrintIcon from '@material-ui/icons/Print';
import MailIcon from '@material-ui/icons/Mail';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';

import MenuBar from './MenuBar';
import ServicesSection from './ServicesSection';
import ShapeBanner from './ShapeBanner';
import About from './About';
import Treatments from './Treatments';
import Footer from './Footer';
import Hamburger from './Hamburger';

import bannerImg from './img/banner_sm.jpg';
import particleCurves from './img/particleCurve.svg';

const useStyles = makeStyles(theme => ({
    root: {
        overflow: "hidden",
        maxWidth: "100vw"
    },
    banner: {
        width: "100vw",
        objectFit: "cover",
        [theme.breakpoints.down("sm")]: {
            objectPosition: "0 0",
            
        },
        [theme.breakpoints.up("md")]: {
            objectPosition: "0 -250px",
        },
        
        position: "absolute",
        zIndex: 1,
        filter: "blur(4px)",
        opacity: "100%",
        transform: "scale(1.1)"
    },
    overlay: {
        width: "100vw",
        position: "absolute",
        zIndex: 3,
        opacity: "50%"
    },
    landingText: {
        fontFamily: "Playfair Display",
        [theme.breakpoints.up("sm")]: {
            fontSize: 32,
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: 14,
            
        },
        color: "#fff",
        letterSpacing: 2
    },
    subText: {
        [theme.breakpoints.up("lg")]: {
            fontSize: 14,
            textAlign: "center",
            maxWidth: "100%",
        },
        [theme.breakpoints.down("md")]: {
            fontSize: 12,
            textAlign: "left",
            maxWidth: "100%",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: 10,
            textAlign: "left",
            maxWidth: "75%",
        },
        fontFamily: "Noto Sans JP",
        letterSpacing: 2,
        
    },
    landingButton: {
        fontFamily: "Noto Sans JP",
        [theme.breakpoints.up("lg")]: {
            fontSize: 14,
        },
        [theme.breakpoints.down("md")]: {
            fontSize: 12,
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: 10,
        },
        letterSpacing: 2,
        color: "#fff",
        borderColor: "#fff",
        paddingTop: "6px",
        paddingBottom: "6px"
    },
    bannerContainer: {
        backgroundImage: `url('${bannerImg}')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        filter: "blur(4px)",
    },
    bannerClip: {
        clipPath: "polygon(100% 0, 100% 75%, 50% 100%, 0 75%, 0 0)",
    },
    infoText: {
        fontFamily: "Noto Sans JP",
        letterSpacing: 1
    },
    smallText: {
        fontSize: 14,
    },
    matchHeight: {
        [theme.breakpoints.down("sm")]: {
            height: "100%"
        },
        
    },
    gradientStrip: {
        background: "linear-gradient(to left, #edc4b3, #c38e70, #774936)"
    },
    sectionTitle: {
        marginTop: 0,
        marginBottom: 0,
        fontSize: 32,
        fontFamily: "Playfair Display",
        fontWeight: 300,
        letterSpacing: 2
    },
    sectionSubTitle: {
        marginTop: 2,
        marginBottom: 0,
        fontSize: 14
    },
}));

export default function Home() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Hamburger id="hamburger"></Hamburger>
            <MenuBar></MenuBar>
            <Box id="home" position="relative" width={1} maxWidth={1} top={0} height={{ xs: 250, sm: 400, md: 450 }} overflow="hidden">
                <Box id="util-overlay" style={{ clipPath: "polygon(100% 0, 100% 75%, 50% 75%, 0 75%, 0 0)" }}
                    height={{ xs: 250, sm: 400, md: 450 }} bgcolor="#000" zIndex={1} width="100vw" position="absolute"></Box>
                <Box id="landing-overlay" className={`${classes.overlay} ${classes.bannerClip}`} 
                    height={{ xs: 250, sm: 400, md: 450 }} bgcolor="#000"></Box>
                <Box id="landing-banner" className={`${classes.bannerContainer} ${classes.bannerClip}`} position="absolute" width={1} 
                    height={{ xs: 250, sm: 400, md: 450 }} zIndex={2}></Box>
                

                <Box id="landing-container" position="relative" width={1} height={1} display="flex" flexDirection="row">

                    <Box position="relative" zIndex={3} display="flex" flexDirection="col" mt="auto" mb={{xs: 6, sm: 10, md: 6, lg: 10}} mx="auto">
                        <Box width={{ xs: 1/3, sm: 1/2 }} height={1/2} ml="auto" mr={1} textAlign={{xs: "right", md: "center"}}>
                            <img src="carefoot.svg" style={{ objectFit: "contain", width: "50%", transform: "scaleX(-1)" }}></img>
                        </Box>

                        <Box className={classes.landingText} width={{ xs: 1/2, md: 1/3, lg: 1/2 }} height={1/2} mr={{ xs: 0, md: "auto"}} ml={1} 
                            justifyContent="center" alignItems="center" textAlign={{xs: "left", lg: "center"}}>
                            
                            Experts in Footcare

                            <p className={classes.subText}>Over 25 years of experience</p>
                            <Button href="/#contact" variant="outlined" size="small" className={classes.landingButton}>Contact Us</Button>
                        </Box>
                    </Box>

                </Box>
            </Box>
            <Box width={1} display="flex" flexDirection="col" flexWrap="wrap" p={1} justifyContent="center" mt={1} mb={3} maxWidth={1}>
                <Box mx="auto" order={{ xs: 2, md: 1 }} width={{xs: 1/2, md: 1/4}} mt={{ xs: 2, md: 0 }} pr={{ xs: 1, md: 0 }} flexWrap="wrap">
                    <Paper elevation={3}>
                        <Box justifyContent="center" display="flex" flexDirection="row">
                            <Grid container direction="row" justify="center" alignItems="center">
                                <Grid item xs={12}>
                                    <Box width={1} justifyContent="center" display="flex" mt={1}>
                                        <AccessAlarmIcon></AccessAlarmIcon>
                                    </Box>
                                </Grid>
                                <Box className={classes.infoText} width={1} pb={1}>
                                    <Grid item xs={12}>
                                        <Box width={1} my={1} justifyContent="center" display="flex">Hours</Box>
                                        <Box width={1} height="3px" className={classes.gradientStrip} boxShadow={2}></Box>
                                    </Grid>
                                    
                                    <Grid item xs={12}>
                                        <Box width={1} px={1} mt={1} justifyContent="center" display="flex" flexDirection="row" className={classes.smallText}>
                                            <Box width={1/2} justifyContent="left" display="flex">
                                                Mon.
                                            </Box>
                                            <Box width={1/2} justifyContent="right" display="flex">
                                                9:30 am - 7 pm
                                            </Box>
                                        </Box>
                                        <Box width={1} px={1} mt={1} justifyContent="center" display="flex" flexDirection="row" className={classes.smallText}>
                                            <Box width={1/2} justifyContent="left" display="flex">
                                                Tues.
                                            </Box>
                                            <Box width={1/2} justifyContent="right" display="flex">
                                                9:30 am - 4:30 pm
                                            </Box>
                                        </Box>
                                        <Box width={1} px={1} mt={1} justifyContent="center" display="flex" flexDirection="row" className={classes.smallText}>
                                            <Box width={1/2} justifyContent="left" display="flex">
                                                Wed.
                                            </Box>
                                            <Box width={1/2} justifyContent="right" display="flex">
                                                9:30 am - 4:30 pm
                                            </Box>
                                        </Box>
                                        <Box width={1} px={1} mt={1} justifyContent="center" display="flex" flexDirection="row" className={classes.smallText}>
                                            <Box width={1/2} justifyContent="left" display="flex">
                                                Thurs.
                                            </Box>
                                            <Box width={1/2} justifyContent="right" display="flex">
                                                9:30 am - 4:30 pm
                                            </Box>
                                        </Box>
                                        <Box width={1} px={1} mt={1} justifyContent="center" display="flex" flexDirection="row" className={classes.smallText}>
                                            <Box width={1/2} justifyContent="left" display="flex">
                                                Fri.
                                            </Box>
                                            <Box width={1/2} justifyContent="right" display="flex">
                                                9:00 am - 1 pm
                                            </Box>
                                        </Box>
                                        <Box width={1} px={1} mt={1} justifyContent="center" display="flex" flexDirection="row" className={classes.smallText}>
                                            <Box width={1/2} justifyContent="left" display="flex">
                                                Sat. & Sun.
                                            </Box>
                                            <Box width={1/2} justifyContent="right" display="flex" color="error.main">
                                                Closed
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Box>
                    </Paper>
                </Box>
                
                <Box mx="auto" order={{ xs: 3, md: 3 }} width={{xs: 1/2, md: 1/4}} mt={{ xs: 2, md: 0 }} pl={{ xs: 1, md: 0 }}>
                    <Paper elevation={3} className={classes.matchHeight}>
                        <Box justifyContent="center" display="flex" flexDirection="row">
                            <Grid container direction="row" justify="center" alignItems="center">
                                <Grid item xs={12}>
                                    <Box width={1} justifyContent="center" display="flex" mt={1}>
                                        <ContactPhoneIcon></ContactPhoneIcon>
                                    </Box>
                                </Grid>
                                
                                <Grid item xs={12} className={classes.infoText}>
                                    <Box width={1} my={1} justifyContent="center" display="flex">Get In Touch</Box>
                                    <Box width={1} height="3px" className={classes.gradientStrip} boxShadow={2}></Box>
                                </Grid>

                                <Box className={classes.infoText} width={1} pb={1}>
                                    <Box display="flex" flexDirection="row" mt={1} px={1} width={1} mx="auto">
                                        <Box width={1/4} justifyContent="center" alignSelf="center" display="flex">
                                            <PhoneIcon fontSize="small"></PhoneIcon>
                                        </Box>
                                        <Box width={3/4} className={classes.smallText} textAlign="right" pr={{ xs: 0, md: 4 }}>
                                            905-764-3668
                                        </Box>
                                    </Box>
                                    <Box display="flex" flexDirection="row" px={1} my={2} width={1} mx="auto">
                                        <Box width={1/4} justifyContent="center" alignSelf="center" display="flex">
                                            <PrintIcon fontSize="small"></PrintIcon>
                                        </Box>
                                        <Box width={3/4} className={classes.smallText} textAlign="right" pr={{ xs: 0, md: 4 }}>
                                            905-764-3669
                                        </Box>
                                    </Box>
                                    <Box display="flex" flexDirection="row" px={1} my="auto" width={1} mx="auto" mb={2}>
                                        <Box width={1/4} justifyContent="center" alignSelf="center" display="flex">
                                            <MailIcon fontSize="small"></MailIcon>
                                        </Box>
                                        <Box width={3/4} className={classes.smallText} textAlign="right" pr={{ xs: 0, md: 4 }}>
                                            heeltotoes@gmail.com
                                        </Box>
                                    </Box>

                                    <Box mt="auto" style={{ fontSize: 12 }} px={2} pt={1} textAlign="center">
                                        Call today to speak directly to our podiatry team and learn what we can do for you.
                                    </Box>
                                    
                                </Box>
                            </Grid>
                        </Box>
                    </Paper>
                </Box>

                <Box mx="auto" order={{ xs: 1, md: 2 }} width={{xs: 1, md: 1/3}} mt={{ xs: 1, md: 3 }}>
                    <Paper elevation={3}>
                        <Box justifyContent="center" display="flex" flexDirection="row">
                            <Grid container direction="row" justify="center" alignItems="center">
                                <Grid item xs={12}>
                                    <Box width={1} justifyContent="center" display="flex" mt={1}>
                                        <LocalHospitalIcon></LocalHospitalIcon>
                                    </Box>
                                </Grid>
                                <Box className={classes.infoText} width={1} pb={1}>
                                    <Grid item xs={12}>
                                        <Box width={1} my={1} justifyContent="center" display="flex">What is Chiropody?</Box>
                                        <Box width={1} height="3px" className={classes.gradientStrip} boxShadow={2}></Box>
                                    </Grid>
                                    <Box px={1}>
                                        <Box fontFamily="Playfair Display" color="#b9b9b9" mt={1} mx="auto" textAlign="center">
                                            (Chi &#9900; rop &#9900; o &#9900; dy)
                                        </Box>
                                        <p mt={0} className={classes.smallText}>
                                            The practice of Chiropody is the {" "}
                                            <mark style={{ backgroundColor: "#edc4b3" }}>assessment</mark>, {" "}
                                            <mark style={{ backgroundColor: "#edc4b3" }}>prevention</mark> and {" "}
                                            <mark style={{ backgroundColor: "#edc4b3" }}>treatment</mark> of {" "}
                                            <span style={{ textDecoration: "underline", textDecorationColor: "#C38E70"}}>diseases or disorders</span> of 
                                            the <span style={{ color: "#774936" }}>feet and ankles</span> by 
                                            therapeutic, surgical, orthotic and palliative means. Chiropody is 
                                            practiced by regulated practitioners who have undergone government established and recognized 
                                            programs and examinations. 
                                        </p>
                                    </Box>
                                    
                                </Box>
                            </Grid>
                        </Box>
                    </Paper>
                </Box>

            </Box>
        
            <Box id="services" justifyContent="center" textAlign="center" mb={3} height={{xs: "258px", md: "492px"}} style={{ backgroundImage: `url(${particleCurves})`, backgroundRepeat: "no-repeat", backgroundSize: {xs: "contain", md: "cover"}, backgroundPosition: "center" }}>
                <Box px={{ xs: 1, md: 6 }}>
                    <h4 className={classes.sectionSubTitle} style={{fontWeight: 400}}>Products & Foot Care</h4>
                    <h1 className={classes.sectionTitle} mt={0}>Services</h1>
                    <Box width={{xs: 1, md: 1/2}} height="3px" className={classes.gradientStrip} boxShadow={2} mx="auto"></Box>
                    <ServicesSection></ServicesSection>
                </Box>
            </Box>

            <Treatments></Treatments>

            <ShapeBanner></ShapeBanner>
            <About></About>
            <Footer></Footer>
        </div>
        
    );
}