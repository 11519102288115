import React from 'react';
import { Box, makeStyles } from '@material-ui/core';

import doctorImg from './img/doctor.png';
import linesSVG from './img/lines.svg';
import ccoImg from './img/CCO.png';
import oscImg from './img/OSC.png';
import cfpmImg from './img/CFPM.png';

let useStyles = makeStyles(theme => ({
    gradientStrip: {
        background: "linear-gradient(to left, #edc4b3, #c38e70, #774936)"
    },
    sectionTitle: {
        marginTop: 0,
        marginBottom: 0,
        [theme.breakpoints.down("sm")]: {
            fontSize: 24
        },
        [theme.breakpoints.up("md")]: {
            fontSize: 32
        },
        fontFamily: "Playfair Display",
        fontWeight: 300,
        letterSpacing: 2
    },
    sectionSubTitle: {
        marginTop: 2,
        marginBottom: 0,
        [theme.breakpoints.down("sm")]: {
            fontSize: 12
        },
        [theme.breakpoints.up("md")]: {
            fontSize: 14
        },
    },
    affiliateLogoText: {
        [theme.breakpoints.down("sm")]: {
            fontSize: 12
        },
        [theme.breakpoints.up("md")]: {
            fontSize: 18
        },
        fontFamily: "Playfair Display"
    },
    affiliateLogo: {
        [theme.breakpoints.down("sm")]: {
            width: "48px"
        },
        [theme.breakpoints.up("md")]: {
            width: "86px"
        },
        padding: "4px"
    }
}));

function ShapeBanner(props) {
    const classes = useStyles();
    return (
        <div style={{position: "relative", width: "100vw", backgroundImage: `url(${linesSVG})`, backgroundRepeat: "no-repeat", backgroundSize: "200% 80%", backgroundPosition: "center center", margin: "12px 0px 0px 0px"}}>
            <Box display="flex" flexDirection="row" width={5/6} mx="auto" alignItems="center" zIndex={1}>
                <Box width={{xs: 1, md: 1/3}} height={{xs: "250px", md: "350px", xl: "450px"}} mx="auto"
                    style={{ backgroundImage: `url(${doctorImg})`, backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundPosition: "center center"}}>
                </Box>
                <Box width={1} p={2} bgcolor="rgba(0, 0, 0, 0.6)" zIndex={2} position="absolute" top={0} left={0} height={1}>
                    <Box px={{ xs: 1, md: 6 }} color="#fff">
                        <Box mx="auto" width={{xs: 1, md: 1/2}}>
                            <h4 className={classes.sectionSubTitle} style={{fontWeight: 400}}>Recognized & Trusted</h4>
                            <h1 className={classes.sectionTitle} mt={0}>Professional Affiliates</h1>
                        </Box>
                        
                        <Box width={{xs: 1, md: 1/2}} height="3px" className={classes.gradientStrip} boxShadow={2} mx="auto"></Box>
                        <Box display="flex" flexDirection="row" flexWrap="wrap" mt={4} mb="2">
                            <Box className={classes.affiliateLogoText} display="flex" flexDirection="col" alignItems="center" width={1/2} justifyContent="center">
                                <a href="https://cocoo.on.ca/" rel="noopener noreferrer" target="_blank"><img className={classes.affiliateLogo} src={ccoImg}></img></a>
                                College of Chirpodists of Ontario
                            </Box>
                            <Box className={classes.affiliateLogoText} display="flex" flexDirection="col" alignItems="center" width={1/2} justifyContent="center">
                                <a href="https://www.ontariochiropodist.com/" target="_blank" rel="noopener noreferrer"><img className={classes.affiliateLogo} src={oscImg}></img></a>
                                Ontario Society of Chiropodists
                            </Box>
                            <Box className={classes.affiliateLogoText} display="flex" flexDirection="col" alignItems="center" width={1/2} justifyContent="center" mx="auto" mt={2}>
                                <a href="https://www.podiatryinfocanada.ca/" target="_blank" rel="noopener noreferrer"><img className={classes.affiliateLogo} src={cfpmImg}></img></a>
                                Canadian Federation of Podiatric Medicine
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            
        </div>
        
    );
}

export default ShapeBanner;