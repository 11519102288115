import React from 'react';
import { Box, Paper, makeStyles } from '@material-ui/core';

import HomeIcon from '@material-ui/icons/Home';
import PhoneIcon from '@material-ui/icons/Phone';
import PrintIcon from '@material-ui/icons/Print';
import MailIcon from '@material-ui/icons/Mail';

import debbieImg from './img/debbie.png';
import avatarSVG from './img/avatar.svg';
import mapImg from './img/maps.jpg';

let useStyles = makeStyles(theme => ({
    gradientStrip: {
        background: "linear-gradient(to left, #edc4b3, #c38e70, #774936)"
    },
    sectionTitle: {
        marginTop: 0,
        marginBottom: 0,
        [theme.breakpoints.down("sm")]: {
            fontSize: 24
        },
        [theme.breakpoints.up("md")]: {
            fontSize: 32
        },
        fontFamily: "Playfair Display",
        fontWeight: 300,
        letterSpacing: 2
    },
    sectionSubTitle: {
        marginTop: "24px",
        marginBottom: 0,
        [theme.breakpoints.down("sm")]: {
            fontSize: 12
        },
        [theme.breakpoints.up("md")]: {
            fontSize: 14
        },
    },
    avatar: {
        [theme.breakpoints.down("sm")]: {
            width: "64px",
            height: "64px"
        },
        [theme.breakpoints.up("md")]: {
            width: "72px",
            height: "72px"
        }
    },
    avatarTitle: {
        fontFamily: "Playfair Display",
        fontSize: 18,
        [theme.breakpoints.down("sm")]: {
            paddingLeft: 8
        },
        [theme.breakpoints.up("md")]: {
            paddingLeft: 24
        }
    },
    description: {
        fontSize: 14,
        paddingLeft: "12px",
        paddingRight: "12px"
    },
    map: {
        [theme.breakpoints.down("sm")]: {
            width: "250px"
        },
        [theme.breakpoints.up("md")]: {
            width: "300px"
        },
        border: "1px solid #434343",
        
    },
    matchHeight: {
        height: "100%"
        
    },
    subText: {
        color: "#000",
        opacity: "50%",
        padding: 0,
        margin: 0,
        fontSize: "10px",
        textAlign: "center",
    }
}));

function About(props) {
    const classes = useStyles();
    return (
        <Box width={1} justifyContent="center" mb={2} display="flex" flexDirection="row" flexWrap="wrap" zIndex={2} position="relative">
            <Box id="team" className={classes.matchHeight} width={{xs: 1, md: 1/2}} px={2} order={1}>
                <Paper elevation={2}>
                    <Box mx="auto" textAlign="center">
                        <h4 className={classes.sectionSubTitle} style={{fontWeight: 400, paddingTop: "16px"}}>Reliable, Friendly, Experienced</h4>
                        <h1 className={classes.sectionTitle} mt={0}>Meet Our Team</h1>
                    </Box>
                    <Box width={1} height="3px" className={classes.gradientStrip} boxShadow={2} mx="auto" mt={1}></Box>
                    <Box display="flex" flexDirection="col" mt={1}>
                        <Box alignItems="center" p={1}>
                            <Box display="flex" flexDirection="row" ml={{xs: 2, md: 8}} alignItems="center">
                                <img className={classes.avatar} src={debbieImg}></img>
                                <h4 className={classes.avatarTitle}>DEBORAH L. COHEN-BENUDIZ B.SC., D.CH.</h4>
                            </Box>
                            
                            <p className={classes.description}>Deborah L. Cohen-Benudiz, founder of the Heel To Toes clinic in Thornhill, 
                            obtained a Hons. BSc. in Microbiology and Human Biology at University of Toronto in 1989. 
                            Deborah went on to study Chiropody at the Michener Institute of Applied Sciences 
                            where she obtained a 3-year diploma in Chiropody in 1992. While at the Michener, 
                            Deborah gained extensive clinical experience at Branson Hospital
                             Clinic in North York and West Park Hospital Chiropody Clinic. From 1992-2009, Deborah held the position of Senior Staff 
                             Chiropodist at Baycrest Centre for Geriatric Care in Toronto. In 2009 Deborah officially opened up Heel To Toes Footcare 
                             and Orthotics Clinic where she practices her full scope of practice.</p>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="col" mt={1}>
                        <Box alignItems="center" p={1}>
                            <Box display="flex" flexDirection="row" ml={{xs: 2, md: 8}}>
                                <img src={avatarSVG} className={classes.avatar}></img>
                                <h4 className={classes.avatarTitle}>FELICIA GOVE</h4>
                            </Box>
                            
                            <p className={classes.description}>Felicia is our friendly admin at the Heel To Toes Clinic who greets you
                            on the phone or in person. She has been with Heel To Toes since 2009 and is always happy to help you with your
                            appointments, billing, and insurance inquiries.</p>
                        </Box>
                    </Box>
                </Paper>
            </Box>

            <Box id="contact" width={{xs: 1, md: 1/2}} px={2} order={2}>
                <Paper elevation={2}>
                    <Box mx="auto" textAlign="center">
                        <h4 className={classes.sectionSubTitle} style={{fontWeight: 400, paddingTop: "16px"}}>Questions or Concerns?</h4>
                        <h1 className={classes.sectionTitle} mt={0}>Contact Us</h1>
                    </Box>
                    <Box width={1} height="3px" className={classes.gradientStrip} boxShadow={2} mx="auto" mt={1}></Box>
                    <Box display="flex" flexDirection="col" flexWrap="wrap" mt={2} px={2}>
                        <Box display="flex" flexDirection="row" flexWrap="wrap">
                            <Box display="flex" flexDirection="row" mt={1} px={1} width={1} mx="auto" justifyContent="center">
                                <Box justifyContent="center" alignSelf="center" display="flex">
                                    <PhoneIcon fontSize="small"></PhoneIcon>
                                </Box>
                                <Box width={3/4} className={classes.smallText} textAlign={{xs: "center", md: "right"}} pr={{ xs: 0, md: 4 }}>
                                    905-764-3668
                                </Box>
                            </Box>
                            <Box display="flex" flexDirection="row" px={1} my={2} width={1} mx="auto" justifyContent="center">
                                <Box justifyContent="center" alignSelf="center" display="flex">
                                    <PrintIcon fontSize="small"></PrintIcon>
                                </Box>
                                <Box width={3/4} className={classes.smallText} textAlign={{xs: "center", md: "right"}} pr={{ xs: 0, md: 4 }}>
                                    905-764-3669
                                </Box>
                            </Box>
                            <Box display="flex" flexDirection="row" px={1} my="auto" width={1} mx="auto" mb={2} justifyContent="center">
                                <Box justifyContent="center" alignSelf="center" display="flex">
                                    <MailIcon fontSize="small"></MailIcon>
                                </Box>
                                <Box width={3/4} className={classes.smallText} textAlign={{xs: "center", md: "right"}} pr={{ xs: 0, md: 4 }}>
                                    heeltotoes@gmail.com
                                </Box>
                            </Box>
                            <Box display="flex" flexDirection="row" px={1} my="auto" width={1} mx="auto" mb={2} justifyContent="center">
                                <Box justifyContent="center" alignSelf="center" display="flex">
                                    <HomeIcon fontSize="small"></HomeIcon>
                                </Box>
                                <Box width={3/4} className={classes.smallText} textAlign="right" pr={{ xs: 0, md: 4 }}>
                                    <Box fontFamily="Playfair Display" fontWeight="600" fontSize="16px" width={1} pl={1}>
                                        HEEL TO TOES FOOT CARE AND ORTHOTIC CENTRE
                                    </Box>
                                    Suite #105, 300 John St,

                                    Thornhill, ON L3T 5W4
                                </Box>
                            </Box>
                            <Box width={{xs: 1, md: 1/2}} mx="auto" px="auto" justifyContent="center" display="flex">
                                <a target="_blank" rel="noopener noreferrer"
                                    href="https://www.google.ca/maps/place/Heel+To+Toes+Footcare+and+Orthotic+Centre/@43.8203934,-79.4007039,17z/data=!3m1!4b1!4m5!3m4!1s0x882b2cbc9d61c825:0xe64af048a44d8e75!8m2!3d43.8203934!4d-79.3985152">
                                    <img src={mapImg} className={classes.map}></img>
                                    <p className={classes.subText}>Click to open in Google Maps</p>
                                </a>
                            </Box>
                            
                        </Box>
                        <p className={classes.description}>
                            At the Heel To Toes clinic, 
                            our staff is committed to providing reliable and effective services to patients from throughout the Thornhill community. 
                            We pride ourselves on being friendly and accommodating at all times, 
                            and we will do everything we can to make your visit as pleasant as possible.
                        </p>
                    </Box>
                </Paper>
            </Box>
        </Box>
    );
}

export default About;