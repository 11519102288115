import React from 'react';
import { makeStyles, Box } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            minWidth:"30%"
        },
        [theme.breakpoints.up('lg')]: {
            width:"15%"
        },
        
        height: "auto",
        borderColor: "#fff",
    }
}));

export default function LogoIcon() {
    const classes = useStyles();
    return (
        <Box className={classes.root} borderBottom={1.25} m={1}>
            <img src="logo.svg"></img>
        </Box>
    );
}