import React from 'react';
import { Box, Button } from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';

function Hamburger(props) {
    return (
        <Box display={{xs: "block", md: "none"}} position="fixed" top={12} left={16} zIndex={10}>
            <Button width="36px" height="36px" onClick={() => {toggleMenu();}}>
                <Box display="flex" color="#fff" borderRadius="50%" bgcolor="rgba(0, 0, 0, 0.5)" width="36px" height="36px" justifyContent="center" alignItems="center">
                    <MenuIcon id="hamburger-icon"></MenuIcon>
                </Box>
            </Button>

            <Box id="hamburger-menu" style={{display: "none"}} flexDirection="col" flexWrap="wrap">
                <Box width={1}><Button href="#home" onClick={() => {toggleMenu();}}><Box color="#fff" bgcolor="rgba(0, 0, 0, 0.5)" p={1}>Home</Box></Button></Box>
                <Box width={1}><Button href="#team" onClick={() => {toggleMenu();}}><Box color="#fff" bgcolor="rgba(0, 0, 0, 0.5)" p={1}>Our Team</Box></Button></Box>
                <Box width={1}><Button href="#contact" onClick={() => {toggleMenu();}}><Box color="#fff" bgcolor="rgba(0, 0, 0, 0.5)" p={1}>Contact Us</Box></Button></Box>
                <Box width={1}><Button href="#services" onClick={() => {toggleMenu();}}><Box color="#fff" bgcolor="rgba(0, 0, 0, 0.5)" p={1}>Services</Box></Button></Box>
                <Box width={1}><Button href="#treatments" onClick={() => {toggleMenu();}}><Box color="#fff" bgcolor="rgba(0, 0, 0, 0.5)" p={1}>Treatments</Box></Button></Box>
            </Box>
        </Box>
    );
}

function toggleMenu() {
    let menu = document.getElementById('hamburger-menu');
    let icon = document.getElementById('hamburger-icon');
    if (menu.style.display == "none") {
        icon.style.transform = "rotate(90deg)";
        menu.style.display = "flex";
    }
    else {
        icon.style.transform = "rotate(180deg)";
        menu.style.display = "none";
    }
}

export default Hamburger;