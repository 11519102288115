import React from 'react';
import { makeStyles, Box, Button } from '@material-ui/core';

import logoSVG from './img/logo.svg';
import visaSVG from './img/visa.svg';
import interacSVG from './img/interac.svg';
import masterSVG from './img/mastercard.svg';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

let useStyles = makeStyles(theme => ({
    navLink: {
        fontSize: 16,
        color: "#fff",
        textAlign: "left",
        fontFamily: "Playfair Display"
    },
    shadowTop: {
        boxShadow: "0px -2px 6px 3px rgba(0,0,0,0.5)"
    },
    payIcon: {
        width: "64px"
    }
}));

function Footer(props) {
    const classes = useStyles();
    return (
        <Box width={1} position="relative">
            
            <Box className={classes.shadowTop} position="relative" zIndex={2} px={2} mt={4} bgcolor="#282828" bottom={0}>
                <Box display="flex" flexDirection="row" width={1} alignItems="flex-start" flexWrap="wrap">
                    <Box width={1} mt={1} display="flex" justifyContent="center"><img src={logoSVG} height="32px"></img></Box>
                    <Box width={{xs: 1, md: 1/2}} display="flex" justifyContent="center" color="#fff" flexWrap="wrap" flexDirection="col" my={1}>
                        <Box style={{fontFamily: "Playfair Display", fontSize: 20}} borderBottom={1} width={3/4} pl={1} py={2}>Links</Box>
                        <Box width={3/4} textAlign="left"><Button className={classes.navLink} href="#home">Home</Button></Box>
                        <Box width={3/4} textAlign="left"><Button className={classes.navLink} href="#team">Our Team</Button></Box>
                        <Box width={3/4} textAlign="left"><Button className={classes.navLink} href="#contact">Contact Us</Button></Box>
                        <Box width={3/4} textAlign="left"><Button className={classes.navLink} href="#services">Services</Button></Box>
                        <Box width={3/4} textAlign="left"><Button className={classes.navLink} href="#treatments">Treatments</Button></Box>
                    </Box>
                    <Box width={{xs: 1, md: 1/2}} display="flex" justifyContent="center" color="#fff" flexWrap="wrap" flexDirection="col" my={1} fontFamily="Playfair Display">
                        <Box style={{fontFamily: "Playfair Display", fontSize: 20}} borderBottom={1} width={3/4} pl={1} py={2}>Contact</Box>
                        <Box width={3/4} textAlign="left" mt={1} py={1} pl={1}>
                            <p style={{padding: 0, margin: 0, fontWeight: 600}}>Heel To Toes Foot Care and Orthotic Centre</p>
                            Suite #105, 300 John St,
                            Thornhill, ON L3T 5W4
                        </Box>
                        <Box width={3/4} textAlign="left" mt={1} pb={1} pl={1}>Phone: 905-764-3668</Box>
                        <Box width={3/4} textAlign="left" mt={1} pb={1} pl={1}>Fax: 905-764-3669</Box>
                        <Box width={3/4} textAlign="left" mt={1} pb={1} pl={1}>Email: info@ifixft.ca</Box>
                    </Box>
                    <Box width={{xs: 1, md: 1/2}} display="flex" justifyContent="center" color="#fff" flexWrap="wrap" flexDirection="col" my={1} fontFamily="Playfair Display">
                        <Box style={{fontFamily: "Playfair Display", fontSize: 20}} borderBottom={1} width={3/4} pl={1} py={2}>Hours</Box>
                        <Box width={3/4} textAlign="left" mt={1} py={1} pl={1}>Monday: 9:30 am - 7 pm</Box>
                        <Box width={3/4} textAlign="left" mt={1} pb={1} pl={1}>Tuesday: 9:30 am - 4:30 pm</Box>
                        <Box width={3/4} textAlign="left" mt={1} pb={1} pl={1}>Wednesday: 9:30 am - 4:30 pm</Box>
                        <Box width={3/4} textAlign="left" mt={1} pb={1} pl={1}>Thursday: 9:30 am - 4:30 pm</Box>
                        <Box width={3/4} textAlign="left" mt={1} pb={1} pl={1}>Friday: 9:30 am - 1 pm</Box>
                        <Box width={3/4} textAlign="left" mt={1} pb={1} pl={1}>Sat & Sun: Closed</Box>
                    </Box>
                    <Box width={{xs: 1, md: 1/2}} display="flex" justifyContent="center" color="#fff" flexWrap="wrap" flexDirection="col" my={1} fontFamily="Playfair Display">
                        <Box style={{fontFamily: "Playfair Display", fontSize: 20}} borderBottom={1} width={3/4} pl={1} py={2}>Payment Methods</Box>
                        <Box width={1/2} textAlign="left" mt={1} py={1} pl={1} display="flex" justifyContent="center" alignItems="center"><img className={classes.payIcon} src={masterSVG}></img></Box>
                        <Box width={1/2} textAlign="left" mt={1} pb={1} pl={1} display="flex" justifyContent="center" alignItems="center"><img className={classes.payIcon} src={interacSVG}></img></Box>
                        <Box width={1/2} textAlign="left" mt={1} pb={1} pl={1} display="flex" justifyContent="center" alignItems="center"><img className={classes.payIcon} src={visaSVG}></img></Box>
                        <Box width={1/2} textAlign="left" mt={1} pb={1} pl={1} display="flex" justifyContent="center" alignItems="center"><AttachMoneyIcon></AttachMoneyIcon><Box>Cash & Cheque</Box></Box>
                    </Box>
                    <Box width={1} mx={12} mb={2} textAlign="center" color="#fff" fontFamily="Playfair Display" fontSize={12} style={{opacity: "0.75"}}>Copyright Heel To Toes Foot Care and Orthotic Centre 2020</Box>
                </Box>
            </Box>
            <Box className={classes.shadowTop} position="absolute" width={1} top="-400px" left={0} bgcolor="#434343" height="400px" zIndex={1}></Box>
        </Box>
    );
}

export default Footer;