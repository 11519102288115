import React from 'react';
import { makeStyles, Box, Paper, Button } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

let useStyles = makeStyles(theme => ({
    itemTitle: {
        [theme.breakpoints.down("sm")]: {
            fontSize: 18
        },
        [theme.breakpoints.up("md")]: {
            fontSize: 24
        },
        marginTop: 0,
        marginBottom: 0,
        fontWeight: 400,
        fontFamily: "Playfair Display",
        letterSpacing: 1
    },
    itemDescription: {
        [theme.breakpoints.down("sm")]: {
            fontSize: 14
        },
        [theme.breakpoints.up("md")]: {
            fontSize: 20
        },
        fontWeight: 500,
        marginTop: 1,
        textAlign: "left",
        paddingLeft: "1em",
        paddingRight: "1em",
    },
    gradientStrip: {
        background: "linear-gradient(to left, #edc4b3, #c38e70, #774936)"
    },
    itemTreatment: {
        [theme.breakpoints.down("sm")]: {
            fontSize: 12
        },
        [theme.breakpoints.up("md")]: {
            fontSize: 14
        },
        marginTop: 0,
        marginBottom: 0,
        fontWeight: 300,
    },
    sectionTitle: {
        marginTop: 0,
        marginBottom: 0,
        fontSize: 32,
        fontFamily: "Playfair Display",
        fontWeight: 300,
        letterSpacing: 2
    },
    sectionSubTitle: {
        marginTop: 2,
        marginBottom: 0,
        fontSize: 14
    },
}));

function Treatments(props) {

    let items = [
        {
            title: "Plantar Warts",
            treatment: "Treatments include debridement, application of acids/chemicals, or dry needling.",
            description: "Plantar warts are very stubborn lesions caused by the Human Papilloma Virus (HPV). They are commonly appearing on the soles of the feet and on the toes. Warts can appear similar to corns and they are often mistaken for one another. Warts, otherwise known as veruccae, are usually asymptomatic (painless) however they are inclined to spread to other areas of the feet and the hands."
        },
        {
            title: "Ingrown Nails",
            treatment: "Treatments include removal of nail spike. Topical or Oral antibiotics may be necessary. If condition is chronic, nail surgery may be required.",
            description: "Ingrown toenails are a common medical condition in which the corner or side of a toenail grows into the soft flesh that surrounds it. This results in pain and discomfort, redness and swelling, and may even develop into an infected ingrown toenail. Although they can affect any toe, they usually develop on the big toe."
        },
        {
            title: "Fungal Nails",
            treatment: "Treatment includes debriding nail frequently and Rx or topical anti-fungals.",
            description: "Otherwise known as onychomycosis, is a very unsightly condition that can be hard to treat. The nail becomes infected by fungal spores and it can progress until it affects the nail root (matrix) causing the nail to grow thick. Fungal toe nails can appear with white or yellow patches or streaks in the nail, thick, crumbly, and sometimes has an odour."
        },
        {
            title: "Difficult Nails",
            treatment: "Treatment includes nail reduction and/or removal of nail.",
            description: "Nails may become thickened for many reasons ranging from trauma, infection, poor circulation, and aging. The nail can place immense pressure on the nail bed underneath from footwear and are difficult to cut on your own."
        },
        {
            title: "Callouses",
            treatment: "Treatment includes reducing the callous and eliminating the cause of pressure.",
            description: "Callus is thickened and hard skin due to friction or pressure on the feet. They are commonly present over bony prominences on the feet, such as the ball of the foot or the heel. They appear yellowish in colour."
        },
        {
            title: "Corns",
            treatment: "Treatment includes enucleating the corn and eliminating the cause of pressure.",
            description: "Corns are small, localized areas of thickened skin that develops into a core. Corns can become very painful from standing or tight fitting shoes. They can develop on top of the toes, on the soles of the feet, or in between the toes."
        },
        {
            title: "Cracked Heels",
            treatment: "Treatments include reducing the callous and addressing underlying cause.",
            description: "Cracked skin on the heels is commonly referred to as a fissure. The skin becomes hard and callused; it can split from pressure on the feet. When the fissures are deep they can cause bleeding and pain."
        },
        {
            title: "Ulcers and Wound Care",
            treatment: "Treatment may include debridement, dressings, offloading techniques and addressing the cause.",
            description: "This is a sore on the skin accompanied by the breakdown of surrounding tissue. This typically affects people with diabetes or circulation problems."
        },
        {
            title: "Athelete's Foot",
            treatment: "Treatments include identifying the cause and topical Rx of anti-fungal.",
            description: "Athlete’s foot is a term given to a fungal infection of the skin; often occurring, but not limited to, between the toes. This can be itchy and appear red, swollen, and dry; but may also appear macerated (soggy)."
        },
        {
            title: "Knee, Leg, and Back Pain",
            treatment: "A full biomechanical exam and gait analysis is performed.",
            description: "Your feet are the foundation of your body. If there is a problem with the alignment of your foundation then all the structures resting on your feet can pain because of them. Your foot structure may be the cause of aches your feel in your body."
        },
        {
            title: "Heel Pain/Plantar Fasciitis",
            treatment: "",
            description: "This is one of the most common complaints seen by a chiropodist. Most pain is localized to the heel area and tends to be worse first thing in the morning or after a period of rest. It is caused by inflammation from the overuse of the plantar fascia; a non-elastic band of tissue in the bottom of the foot. The fascia begins to tear off its insertion at the bottom of the heel bone. Spurs occur at the attachment of the fascia to the bone and are asymptomatic on their own, often being the result of plantar fasciitis."
        },
        {
            title: "Sever's Disease",
            treatment: "",
            description: "Otherwise known as apophysitis of the calcaneus, is an inflammation of the growth plate in the heel of growing children. The condition presents as pain in the heel and is caused by repetitive stress to the heel and is thus particularly common in active children."
        },
        {
            title: "Flat Feet",
            treatment: "",
            description: "Flat feet, or Pes Planus, occur in people who don’t have a normal arch in one or both of their feet. The middle of their foot can be in complete contact with the ground when they are standing and the foot rolls inward. This is often caused by genetics and can lead to many related problems if left untreated."
        },
        {
            title: "Achilles Tendinitis",
            treatment: "",
            description: "The Achilles tendon is a continuation of the two calf muscles in the back of your leg and attaches into the back of the heel bone. This tendon can become inflamed and painful often brought on from injury or activity."
        },
        {
            title: "Morton’s Neuroma",
            treatment: "",
            description: "Morton's neuroma is a painful condition that affects the ball of your foot, most commonly the area between your third and fourth toes. Morton's neuroma may feel as if you are standing on a pebble in your shoe or on a fold in your sock."
        },
        {
            title: "Bunions",
            treatment: "",
            description: "A bunion is a bump on the side of the foot at the base of the big toe although they can occur at the base of the small toe as well (bunionette). These are caused by a displacement of the bones in the feet. Bunions are a very common and painful deformity. Poor fitting footwear and genetic factors cause bunions to develop. Corns, callus, ingrown toe nails, and arthritis can develop."
        }
    ];

    const classes = useStyles();
    return (
        <Box id="treatments" display="flex" flexDirection="row" flexWrap="wrap" width={{xs: 1, md: 4/5}} justifyContent="center" mx="auto">
            <Box px={1} width={1} justifyContent="center" my={2} textAlign="center">
                <h4 className={classes.sectionSubTitle} style={{fontWeight: 400}}>We Can Help</h4>
                <h1 className={classes.sectionTitle} mt={0}>Treatments</h1>
                <Box width={{xs: 1, md: 1}} height="3px" className={classes.gradientStrip} boxShadow={2} mx="auto"></Box>
            </Box>
            {items.map((item, index) => <Item entry={item} identifier={item.title} key={index}></Item>)}
        </Box>
    );
}

function Item(props)
{
    const classes = useStyles(); 
    return (
        <Box width={{ xs: 1/2, md: 1/4}} mx="auto" pb={2} px={1}>

            <TreatmentModal id={props.identifier} info={props.entry}></TreatmentModal>

            <Paper elevation={3} style={{ height: { xs: "300px", md: "350px" } }}>
                <Box className={classes.gradientStrip} height="6px" width={3/5} mr="auto"></Box>
                <Box width={1} display="flex" flexDirection="row" px={1} justifyContent="center">
                    <Box width="auto" textAlign="center" my={1}>
                        <h2 className={classes.itemTitle}>{props.entry.title}</h2>
                    </Box>
                </Box>
                
                <Box className={classes.itemTreatment} mb={2} p={1}>{props.entry.treatment}</Box>
                <Box className={classes.gradientStrip} height="3px" width={3/5} ml="auto" mt={2}></Box>
                <Box mx="auto" py={2} textAlign="center">
                    <Button style={{ color: "#edc4b3" }} onClick={() => {
                        document.getElementById(props.identifier).style.display = "block";
                    }}>
                        Learn More
                    </Button>
                </Box>
                
            </Paper>
        </Box>
        
    );
}

function TreatmentModal(props) {
    let classes = useStyles();
    let entry = props.info;
    return (
        <Box id={props.id} position="fixed" top={0} left={0} style={{ display: "none" }} width="100vw" height="100vh" zIndex={5}>
            <Box width={1} height={1} position="absolute" zIndex={7} display="flex">
                <Box bgcolor="#eee" width={3/4} m="auto" overflow="auto">
                    <Box py={2} justifyContent="center" textAlign="center">
                        <Box ml="auto" width="64px" height="42px"><Button onClick={() => {
                            document.getElementById(props.id).style.display = "none";
                        }}><CloseIcon></CloseIcon></Button></Box>
                        <h2 className={classes.itemTitle}>{entry.title}</h2>
                        <Box className={classes.gradientStrip} my={1} mx={1} height="3px" boxShadow={2}></Box>
                        <p className={classes.itemDescription}>{entry.description}</p>
                        <p className={classes.itemTreatment}>{entry.treatment}</p>
                    </Box>
                </Box>
                
            </Box>
            <Box bgcolor="#000" width={1} height={1} style={{ opacity: "75%" }} position="relative" zIndex={6}></Box>
        </Box>
    );
}

export default Treatments;